@import "common/_normalize";

html, body {
  overflow: hidden;
  background: #000;
  padding: 0px; margin: 0px;
  width: 100%; height: 100%;
}

a {
  color: #8af4ff;
  text-decoration: none;
  text-shadow: 0px 0px 0px rgba(138, 244, 255, 0.2);
  transition: text-shadow 0.5s ease;
  &:hover {
    text-decoration: none;
    text-shadow: 0px 0px 5px rgba(138, 244, 255, 0.8);
  }
}
.fullScreen {
  height: 100%;
}
.outer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.content {
  position: relative;
  min-height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  .wrapper {
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  h1 {
    color: #8af4ff;
    display: inline-block;
    font-size: 5vw;
    font-family: Arial, sans-serif;
    font-weight: 200;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    text-shadow: 0px 0px 5px rgba(138, 244, 255, 0.7);
    @media screen and (min-width: 600px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media screen and (min-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  color: #8af4ff;
  padding-bottom: 20px;
  @media screen and (min-width: 568px) {
    height: 60px;
    padding-bottom: 30px;
  }
  img {
    width: 100%;
    max-width: 40px;
    @media screen and (min-width: 568px) {
      max-width: 60px;
      position: relative;
      top: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media screen and (min-width: 600px) {
      max-width: 60px;
      position: relative;
      top: 5px;
      padding-left: 10px;
      padding-right: 5px;
    }
    @media screen and (min-width: 768px) {
      max-width: 80px;
      position: relative;
      top: 8px;
      padding-left: 13px;
      padding-right: 8px
    }
  }
}

.kari {
  font-family: kari-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.2em;
  @media screen and (min-width: 568px) {
    padding-right: 5px;
    font-size: 1.5em
  }
  @media screen and (min-width: 768px) {
    font-size: 1.9em;
  }
}

.acumin {
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.0em;
  @media screen and (min-width: 568px) {
    font-size: 1.0em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.2em;
  }
}

.acumin-black {
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.8em;
  line-height: 1.0em;
  @media screen and (min-width: 568px) {
    font-size: 1.0em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.2em;
  }
}

.acumin-cond {
  font-family: acumin-pro-condensed, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  @media screen and (min-width: 568px) {
    font-size: 1.0em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.2em;
  }
}

.visible-desktop {
  display: none;
  @media screen and (min-width: 568px) {
    display: block;
  }
}

.visible-mobile {
  display: block;
  @media screen and (min-width: 568px) {
    display: none;
  }
}
